(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AnnouncementDialogController', AnnouncementDialogController);

    AnnouncementDialogController.$inject = ['$localStorage', '$sce', 'AditumStorageService', '$state', '$rootScope', '$timeout', '$scope', '$stateParams', 'entity', 'Announcement', 'CommonMethods', 'Modal', 'globalCompany', 'DataUtils', 'SaveImageCloudinary'];

    function AnnouncementDialogController($localStorage, $sce, AditumStorageService, $state, $rootScope, $timeout, $scope, $stateParams, entity, Announcement, CommonMethods, Modal, globalCompany, DataUtils, SaveImageCloudinary) {
        var vm = this;

        vm.announcement = entity;
        vm.announcement.bannerHeight = parseInt(vm.announcement.bannerHeight);
        vm.announcement.imageLoaded = false;
        vm.companyName = $localStorage.companyName;
        if (vm.announcement.id) {
            vm.announcement.videoBannerUrl = $sce.trustAsResourceUrl(vm.announcement.imageBannerUrl);
            if (vm.announcement.useBanner) {
                vm.originalBannerType = vm.announcement.bannerType;
                if (vm.announcement.bannerType == "image") {
                    vm.announcement.bannerType = "1";
                } else {
                    vm.announcement.bannerType = "2";
                }
                vm.fileImageName = vm.announcement.fileName;
            }
            if (vm.announcement.description) {
                vm.announcement.useDescription = 1;
            }
        }
        vm.defineBannerType = function (type) {
            switch (type) {
            case "image/jpeg":
                return "image";
                break;
            case "image/png":
                return "image";
                break;
            case "video/mp4":
                return "video";
                break;
            }
            return "";
        };

        vm.currentDate = new Date();
        if (vm.announcement.imageBannerUrl != null) {
            vm.announcement.imageSet = true;
        }
        vm.sendEmail = 1;
        vm.fileNameStart = vm.announcement.fileName;
        var file;
        vm.announcement.sendEmail = 1;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        var fileImage = null;

        vm.isCreatingOne = $state.includes('announcement.new');
        if (vm.announcement.id == undefined) {
            $rootScope.mainTitle = 'Crear comunicado';
        } else {
            $rootScope.mainTitle = 'Editar comunicado';
        }

        $('#summernote').summernote({
            disableDragAndDrop: true
        });

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.options = {
            height: 150,
            disableDragAndDrop: true,
            toolbar: [
                ['font', ['bold', 'underline', 'clear']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['fullscreen', 'undo', 'redo']],
            ]
        };
        vm.save = save;
        vm.saveAsSketch = saveAsSketch;
        Modal.enteringForm(save, "Publicar", saveAsSketch, "Guardar borrador");
        $scope.$on("$destroy", function () {
            Modal.leavingForm();
        });

        vm.setImage = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.displayImage = base64Data;
                        vm.displayImageType = $file.type;
                        vm.announcement.imageSet = true;
                        vm.fileImageName = $file.name;
                        vm.fileType = $file.type;
                        vm.announcement.imageLoaded = true;
                    });
                });
                fileImage = $file;
            }
        };
        vm.clearImage = function () {
            vm.displayImage = null;
            vm.announcement.imageSet = false;
            vm.fileImageName = "";
        };

        function save() {
            Modal.confirmDialog("¿Está seguro que desea publicar la noticia?", "Una vez publicada será visible para los condóminos", function () {
                Modal.showLoadingBar();
                vm.announcement.publishingDate = moment(new Date()).format();
                vm.announcement.status = 2;
                vm.announcement.companyId = globalCompany.getId();
                if (vm.fileName) {
                    upload();
                } else {
                    saveAnnouncement(vm.announcement);
                }
            });

        }

        function saveAsSketch() {
            vm.isSaving = true;
            Modal.showLoadingBar();
            vm.announcement.publishingDate = moment(new Date()).format();
            vm.announcement.status = 1;
            vm.announcement.companyId = globalCompany.getId();
            saveAnnouncement(vm.announcement);
        }


        function saveAnnouncement(announcement) {
            if (vm.announcement.id) {
                if (vm.announcement.imageLoaded) {
                    vm.announcement.bannerType = vm.defineBannerType(vm.fileType);
                } else {
                    vm.announcement.bannerType = vm.originalBannerType;
                }
            } else {
                vm.announcement.bannerType = vm.defineBannerType(vm.fileType);
            }
            if (announcement.useBanner == 1) {
                if (fileImage !== null) {
                    if (announcement.title == null) {
                        announcement.title = "";
                    }
                    var fileName = makeid(4, vm.fileImageName) + " - " + vm.fileImageName;
                    var uploadTask = AditumStorageService.ref().child('1/banners/' + fileName).put(fileImage);
                    uploadTask.on('state_changed', function (snapshot) {
                        setTimeout(function () {
                            $scope.$apply(function () {
                                vm.progressBanner = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            });
                        }, 1);
                        switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        }
                    }, function (error) {
                        // Handle unsuccessful uploads
                        onSaveError(error);
                    }, function () {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                            vm.announcement.imageBannerUrl = downloadURL;
                            decideActionSave();
                        });
                    });
                } else {
                    decideActionSave();
                }
            } else {
                decideActionSave();
            }
        }

        function makeid(length, fileName) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }


        function upload() {
            var fileName = makeid(4, file.name) + " - " + vm.fileName;
            vm.announcement.fileName = fileName;
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/condominium-records/' + fileName).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    });
                }, 1);
                switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    vm.announcement.fileUrl = downloadURL;
                    vm.announcement.fileName = fileName;
                    saveAnnouncement(vm.announcement);
                });
            });
        }

        function decideActionSave() {
            if (!vm.sendEmail) {
                vm.announcement.sendEmail = 0;
            }
            if (vm.announcement.status == 1) {
                if (vm.announcement.id !== null) {
                    Announcement.update(vm.announcement, onSaveSuccessSketch, onSaveError);
                } else {
                    Announcement.save(vm.announcement, onSaveSuccessSketch, onSaveError);
                }
            } else {
                if (vm.announcement.id !== null) {
                    Announcement.update(vm.announcement, onSaveSuccess, onSaveError);
                } else {
                    Announcement.save(vm.announcement, onSaveSuccess, onSaveError);
                }
            }
        }


        function onNotify(info) {
            vm.progress = Math.round((info.loaded / info.total) * 100);
        }

        function onSaveImageSuccess(data) {
            vm.announcement.imageBannerUrl = "https://res.cloudinary.com/aditum/image/upload/v1501920877/" + data.imageUrl + ".jpg";
            decideActionSave();
        }

        function onSaveSuccess(result) {
            vm.announcement = result;
            $state.go("announcements.announcement");
            Modal.hideLoadingBar();
            Modal.toast("Se ha publicado exitosamente el anuncio en el condominio.");
            vm.isSaving = false;
        }

        function onSaveSuccessSketch(result) {
            vm.announcement = result;
            $state.go("announcements.announcement-sketch");
            Modal.hideLoadingBar();
            Modal.toast("Guardado como borrador.");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            Modal.toast("Ah ocurrido un error, es posible que el contenido del anuncio sea demasiado largo o tenga muchas imagenes en el mismo.");
            Modal.hideLoadingBar();
        }

        vm.datePickerOpenStatus.publishingDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.clear = function () {
            history.back();
        };

        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
            }
        };

    }
})();

