(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AditumGateController', AditumGateController);

    AditumGateController.$inject = ['$scope', '$timeout', 'Principal', '$rootScope', 'CommonMethods', '$stateParams', 'AditumGate', '$http', 'WSQrCode', 'Auth', 'StompManagerSecond'];

    function AditumGateController($scope, $timeout, Principal, $rootScope, CommonMethods, $stateParams, AditumGate, $http, WSQrCode, Auth, StompManagerSecond) {
        var vm = this;
        vm.noData = false;
        vm.showCode = false;
        vm.showCodeExit = false;
        vm.animationDone = true;
        vm.qrData = {active: null};
        vm.sound_url = 'https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/open-gate.mp3?alt=media&token=52a1dd9c-c96f-4ddd-a54a-26fc400ab874';
        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        angular.element(document).ready(function () {
            $('body').removeClass("gray");
            $rootScope.showLogin = false;
            $rootScope.menu = false;
            $rootScope.isInAditumGate = true;
            vm.audio = new Audio(vm.sound_url);
            vm.audio.load();
        });

        $rootScope.openedDoor = false;

        vm.id = $stateParams.id;

        StompManagerSecond.connect().then(function () {
            setTimeout(function () {
                $rootScope.code = vm.id;
                WSQrCode.subscribe(vm.id);
                WSQrCode.receive().then(null, null, openedDoor);
            }, 2000);
        });

        vm.playAlert = function () {
            window.AudioContext = window.AudioContext || window.webkitAudioContext; //fix up prefixing
            var context = new AudioContext(); //context
            var source = context.createBufferSource(); //source node
            source.connect(context.destination); //connect source to speakers so we can hear it
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'arraybuffer'; //the  response is an array of bits
            request.onload = function () {
                context.decodeAudioData(request.response, function (response) {
                    source.buffer = response;
                    source.start(0); //play audio immediately
                }, function () {
                    source.resume();
                });
            };
            request.send();
        };

        function vibrate() {
            if (window.navigator && window.navigator.vibrate) {
                navigator.vibrate([500]);
            }
        }

        function openedDoor(data) {
            vm.openedDoor = true;
            vm.animationDone = false;
            vm.qrData = data;
            if (vm.qrData.inTransit) {
                var name = vm.qrData.name.split(" ");
                // responsiveVoice.speak("Bienvenido", "Spanish Latin American Female");
                // var sound;
                // vm.playAlert()
                vm.audio.play();
                vibrate();
                vm.showCode = false;
                vm.showCodeExit = true;
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.openedDoor = false;
                    });
                }, 3000);
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.animationDone = true;
                    });
                }, 5000);
            } else {
                vm.showCodeExit = false;
                vm.showCode = true;
                vm.playAlert();
                vibrate();
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.openedDoor = false;
                    });
                }, 3000);
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.animationDone = true;
                    });
                }, 6000);
            }
        }

        var urlF = "";
        if (window.location.hostname == "localhost") {
            urlF = 'http://localhost:9000/api/qr-verify/' + vm.id;
        } else {
            urlF = 'https://app.aditumcr.com/api/qr-verify/' + vm.id;
        }
        $http({
            url: urlF,
            method: "GET",
        }).then(function (response) {
            vm.qrData = response.data; // success
            vm.coordinates = vm.qrData.coordinates.split(",");
            vm.wazeLink = "https://www.waze.com/ul?ll=" + vm.coordinates[0] + "%2C" + vm.coordinates[1] + "&navigate=yes&zoom=17";
            if (vm.qrData.active) {
                var text = "ADITUMGATE=" + vm.id;
                var qrcode = new QRCode("qrcode", {
                    text: text,
                    width: width - 150,
                    height: width - 150,
                    colorDark: "black",
                    colorLight: "white",
                    correctLevel: QRCode.CorrectLevel.L
                });
                if (vm.qrData.inTransit) {
                    vm.showCodeExit = true;
                    vm.showCode = false;
                }
                var qrcodeExit = new QRCode("qrcodeExit", {
                    text: text + "EXIT",
                    width: width - 150,
                    height: width - 150,
                    colorDark: "black",
                    colorLight: "white",
                    correctLevel: QRCode.CorrectLevel.L
                });
                vm.showCode = true;
            } else {
                var qrcode = new QRCode("qrcode", {
                    text: "CADUCADO",
                    width: width - 150,
                    height: width - 150,
                    colorDark: "#eeeaea",
                    colorLight: "white",
                    correctLevel: QRCode.CorrectLevel.L
                });
                vm.showCode = true;
            }

        }, function (response) { // optional
            // failed
            vm.noData = true;
            var qrcode = new QRCode("qrcode", {
                text: "CADUCADO",
                width: 250,
                height: 250,
                colorDark: "#d1cdcd",
                colorLight: "white",
                correctLevel: QRCode.CorrectLevel.H
            });
            vm.showCode = true;
        });

    }
})();
