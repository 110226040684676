(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$translate', 'JhiLanguageService', 'tmhDynamicLocale', '$state', '$rootScope', '$localStorage'];

    function JhiLanguageController($translate, JhiLanguageService, tmhDynamicLocale, $state, $rootScope, $localStorage) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.languages = null;
        vm.currentLang = {};

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        vm.defineIcon = function (lang) {
            return lang == "es" ? "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/1%2Fes-language.png?alt=media&token=65de691b-d526-41bd-98e6-483be7e74c62" : "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/1%2Fen-language.png?alt=media&token=7b8884ce-7ea5-4891-a78a-d40c7aa169c1";
        };

        JhiLanguageService.getCurrent().then(function (lang) {
            if ($localStorage.lang) {
                vm.currentLang = $localStorage.lang;
            } else {
                vm.currentLang = lang;
            }
            $rootScope.currentLang = lang;
        });

        function changeLanguage(languageKey) {
            $translate.use(languageKey);
            vm.currentLang = languageKey;
            $localStorage.lang = languageKey;
            $rootScope.currentLang = languageKey;
            tmhDynamicLocale.set(languageKey);
            setTimeout(function () {
                $state.reload();
            }, 200);
        }
    }
})();
