(function () {
    'use strict';

    angular
        .module('aditumApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            '720kb.socialshare',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.router',
            'infinite-scroll',
            'cloudinary',
            'firebase',
            'angularjs-dropdown-multiselect',
            'summernote',
            'ngMaterial',
            'ngMessages',
            'ngSanitize',
            'mdColorPicker',
            'ui.calendar',
            'googlechart',
        ])
        .run(run);

    run.$inject = ['JhiLanguageService','Principal', 'stateHandler', 'translationHandler', '$state', '$rootScope', '$templateCache', '$http', '$filter', 'globalCompany', 'WSVisitorAuthorizationRequest', '$timeout', 'Modal'];

    function run(JhiLanguageService,Principal, stateHandler, translationHandler, $state, vm, $templateCache, $http, $filter, globalCompany, WSVisitorAuthorizationRequest, $timeout, Modal) {
        preloadTemplates($state, $templateCache, $http);
        vm.translations = [];
        vm.currency = "";
        function preloadTemplates($state, $templateCache, $http) {
            angular.forEach($state.get(), function (state, key) {
                if (state.templateUrl !== undefined && state.preload !== false) {
                    $http.get(state.templateUrl, {cache: $templateCache});
                }
            });
        }

        vm.translate = function (text) {
            return vm.translations[text];
        }
        JhiLanguageService.getCurrent().then(function (lang) {
            vm.currentLang = lang;
        });
        vm.unsubscribeVisitorAuthorizationRequest = function (houseId) {
            WSVisitorAuthorizationRequest.unsubscribe(houseId)
        }

        vm.subscribeVisitorAuthorizationRequest = function () {
            WSVisitorAuthorizationRequest.subscribe(globalCompany.getHouseId())
            $timeout(function () {
                WSVisitorAuthorizationRequest.receive(globalCompany.getHouseId()).then(null, null, receiveAuthorization);
            }, 500)
        }

        function receiveAuthorization(data) {
            if ($state.current.name != "home-mobile-menu") {
                Modal.actionToast("Solicitud de ingreso para tu filial", "Ver detalle", function () {
                    $state.go("home");
                });
            }
        }

        Principal.identity().then(function (account) {
            if (account !== null) {
                switch (account.authorities[0]) {
                    case "ROLE_OWNER" || "ROLE_USER":
                        $timeout(function () {
                            vm.subscribeVisitorAuthorizationRequest()
                        }, 2000)
                }
            }
        })


        vm.iconNavBar = "menu";
        vm.domains = [
            {
                id: 1,
                condominiums: [1, 2, 5],
                domain: "app.aditumcr.com",
                companyName: "ADITUM",
                title: "ADITUM",
                favIcon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Faditum-favicon.png?alt=media&token=65393e1c-156a-4a1c-8573-db8146db9ec2",
                loginLogo: {
                    url: "content/images/aditum-logo-login.png",
                    // url: "content/images/aditum-nav-login.png",
                    height: "75",
                    width: ""
                },
                navBarLogo: {
                    big: {
                        url: "content/images/aditum-logo-login.png",
                        urlColor: "content/images/LogoWebHor645x200.png",
                        height: "43",
                        width: ""
                    }, small: {
                        url: "content/images/aditum-logo-login.png",
                        // url: "content/images/aditum-small-nav.png",
                        height: "37",
                        width: ""
                    }
                },
            },
            {
                id: 2,
                condominiums: [4],
                domain: "app.convivecr.com",
                companyName: "Convive",
                title: "Convive - Administración de Condominios",
                favIcon: "content/images/convive-favicon.png",
                loginLogo: {
                    url: "content/images/condovive-h-login.png",
                    height: "120",
                    width: ""
                },
                navBarLogo: {
                    big: {
                        url: "content/images/condovive-h-login.png",
                        urlColor: "content/images/condovive-h-login.png",
                        poweredBy: "content/images/poweredByAditum.png",
                        height: "70",
                        width: ""
                    }, small: {
                        url: "content/images/condovive-h-login.png",
                        height: "70",
                        width: ""
                    }
                },
            },
            {
                id: 3,
                condominiums: [4],
                domain: "app.concasa.life",
                companyName: "CONCASA",
                title: "Concasa Life",
                favIcon: "content/images/concasa-life-favicon.png",
                loginLogo: {
                    url: "content/images/concasa-life-logo.png",
                    height: "160",
                    width: "200"
                },
                navBarLogo: {
                    big: {
                        url: "content/images/concasa-life-logo.png",
                        urlColor: "content/images/concasa-life-logo.png",
                        poweredBy: "content/images/poweredByAditum.png",
                        height: "35",
                        width: ""
                    }, small: {
                        url: "content/images/concasa-life-logo.png",
                        height: "30",
                        width: ""
                    }
                },
            },
        ];
        vm.currentDomain = window.location.host;
        var exist = false;
        for (var i = 0; i < vm.domains.length; i++) {
            if (vm.domains[i].domain == vm.currentDomain) {
                vm.adminCompany = vm.domains[i];
                exist = true;
                changeFavicon(vm.adminCompany.favIcon)
            }
        }
        if (!exist) {
            vm.adminCompany = vm.domains[0];
            changeFavicon(vm.adminCompany.favIcon)
        }

        vm.marginFab = function () {
            return $("#footer-menu").is(":visible");
        }

        function changeFavicon(src) {
            var link = document.createElement('link'),
                oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }

        stateHandler.initialize();
        translationHandler.initialize();
        vm.isInLogin = $state.includes('home');
        vm.isInManual = $state.includes('manualResidente');
        vm.loadingDash = false;
        vm.backgroundSelectCompany = false;
        vm.selectedAlready = false;
        vm.$state = $state;
        vm.menu = true;
        // Initialize Firebase
        var config = {
            apiKey: "AIzaSyC72bE53igXS39tKAZXUsWAsLimMiKnEEs",
            authDomain: "padron-electoral-lh.firebaseapp.com",
            databaseURL: "https://padron-electoral-lh.firebaseio.com",
            projectId: "padron-electoral-lh",
            storageBucket: "padron-electoral-lh.appspot.com",
            messagingSenderId: "720753236578"
        };

        firebase.initializeApp(config);
        vm.onSwipeUp = function (ev, target) {
            $('.fab-oficial').hide("scrollDown");
            $('.hide-fab').hide("scrollDown");
            $('.md-tabs-up').hide("scrollDown");
        };
        vm.onSwipeDown = function (ev, target) {
            $('.fab-oficial').show("scrollDown");
            $('.hide-fab').hide("scrollDown");
            $('.md-tabs-up').show("scrollDown");
        };

        function detectMob() {
            return ((window.innerWidth <= 850) && (window.innerHeight <= 900));
        }

        $(function () {
            var lastScrollTop = 0, delta = 5;
            $('.all-content').scroll(function (event) {
                var st = $(this).scrollTop();
                if (Math.abs(lastScrollTop - st) <= delta)
                    return;
                if (st > lastScrollTop) {
                    // downscroll code
                    if (detectMob()) {
                        vm.onSwipeUp()
                    }
                } else {
                    // upscroll code
                    if (detectMob()) {
                        vm.onSwipeDown()
                    }
                }
                lastScrollTop = st;
            });
        });
        vm.navigated = false;
        vm.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            if (from.name) {
                vm.navigated = true;
            }
        });
        vm.EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        vm.showContent = false;
        vm.isShowingLoadingBar = false;
        vm.inForm = false;
        vm.inDetail = false;
        vm.secondBtnForm = true;

        vm.fMoney = function (amount) {
            var decimal = vm.currency == "$" ? 2 : 2;
            return vm.currency + " " + $filter('currency')(amount, "", decimal);
        }

        vm.truncateText = function(text, length) {
            if (text.length <= length) {
                return text;
            } else {
                return text.substring(0, length) + '...';
            }
        };
        vm.fMoneyNoCurrency = function (amount) {
            var decimal = vm.currency == "$" ? 2 : 2;
            return vm.currency + " " + (amount, "", decimal);
        }

        vm.fMoneyClean = function (amount) {
            var decimal = vm.currency == "$" ? 2 : 2;
            return $filter('currency')(amount, "", decimal);
        }

        vm.fMoneyCurrencyDateChange = function (amount, date) {
            var date = new Date(date);
            var decimal = vm.currency == "$" ? 2 : 2;
            var exceptions = [{
                id: 47,
                date: new Date(2022, 1, 1),
                currency: "$"
            },
                {
                    id: 55,
                    date: new Date(2023, 1, 2),
                    currency: "$"
                },
                {
                    id: 70,
                    date: new Date(2024, 3, 1),
                    currency: "$"
                },
                {
                    id: 43,
                    date: new Date(2024, 3, 1),
                    currency: "$"
                }
            ]
            for (var i = 0; i < exceptions.length; i++) {
                var ex = exceptions[i];
                if (globalCompany.getId() == ex.id) {
                    if (date.getTime() <= ex.date.getTime()) {
                        return ex.currency + " " + $filter('currency')(amount, "", decimal);
                    }
                }
            }

            return vm.currency + " " + $filter('currency')(amount, "", decimal);
        }


        vm.fMoneyNoCurrency = function (amount) {
            var decimal = vm.currency == "$" ? 2 : 2;
            return $filter('currency')(amount, "", decimal);
        }

        vm.fMoneyNoCurrencyExport = function (exporting, amount) {
            if(!exporting) {
                var decimal = vm.currency == "$" ? 2 : 2;
                return $filter('currency')(amount, "", decimal);
            }else{
                amount = $filter('currency')(amount + "", "", 2).replace(/\./g, '');
                return amount.trim();
            }
        }

        vm.fMoneyExport = function (exporting, amount) {
            if (!exporting) {
                return vm.fMoney(amount)
            } else {
                amount = $filter('currency')(amount + "", "", 2).replace(/\./g, '');
                if (vm.adminCompany.id == 2) {
                    return amount.replace(",", ".").trim();
                } else {
                    return amount.trim();
                }
            }
        }

        vm.fMoneyExportChangingCurrency = function (exporting, amount, date) {
            if (!exporting) {
                return vm.fMoneyCurrencyDateChange(amount, date)
            } else {
                amount = $filter('currency')(amount + "", "", 2).replace(/\./g, '');
                if (vm.adminCompany.id == 2) {
                    return amount.replace(",", ".").trim();
                } else {
                    return amount.trim();
                }
            }
        }

        vm.fMoneyBankExport = function (exporting, currency, amount) {
            if (!exporting) {
                return vm.fMoneyBank(currency, amount)
            } else {
                var decimal = currency == "$" ? 2 : 2;
                return " " + $filter('currency')(amount, "", decimal);
            }
        }

        vm.fMoneyBank = function (currency, amount) {
            var decimal = currency == "$" ? 2 : 2;
            return currency + " " + $filter('currency')(amount, "", decimal);
        }

        vm.setInvalidForm = function (i) {
            vm.isInvalidForm = i;
        };
        vm.back = function () {
            if (vm.backState != undefined) {
                if (vm.backAtribute != undefined) {
                    $state.go(vm.backState, vm.backAtribute)
                } else {
                    $state.go(vm.backState)
                }
            } else {
                window.history.back();
            }
            vm.backState = undefined;
            vm.backAtribute = undefined;
        };
        vm.formAction = function () {
            setTimeout(function () {
                vm.action();
            }, 30)
        }
        vm.formAction2 = function () {
            setTimeout(function () {
                vm.action2();
            }, 30)
        }
    }
})();
