(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PaymentsPerHouseController', PaymentsPerHouseController);

    PaymentsPerHouseController.$inject = ['$translate','$state', 'Payment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$rootScope', '$localStorage', '$scope', 'Resident', 'Modal', 'Principal', 'CommonMethods', 'globalCompany', 'House'];

    function PaymentsPerHouseController(translate, $state, Payment, ParseLinks, AlertService, paginationConstants, pagingParams, $rootScope, $localStorage, $scope, Resident, Modal, Principal, CommonMethods, globalCompany, House) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.isReady = false;
        vm.transition = transition;
        vm.loadAll = loadAll;
        var houseId;
        vm.deleted = false;
        Principal.identity().then(function (account) {
            vm.account = account;
            switch (account.authorities[0]) {
            case "ROLE_MANAGER":
                $rootScope.mainTitle = "Contabilidad filiales";
                houseId = $localStorage.houseSelected.id;
                break;
            case "ROLE_ACCOUNTANT":
                $rootScope.mainTitle = "Contabilidad filiales";
                houseId = $localStorage.houseSelected.id;
                break;
            case "ROLE_USER":
                $rootScope.mainTitle = "Pagos";
                $rootScope.active = "paymentsResidentAccount";
                houseId = globalCompany.getHouseId();
                break;
            case "ROLE_OWNER":
                $rootScope.mainTitle = "Pagos";
                $rootScope.active = "paymentsResidentAccount";
                houseId = globalCompany.getHouseId();
                break;
            }
            loadAll();
        });


        vm.verifyPaymentAbleToDelete = function(payment, index){
            var deadline = new Date('2024-05-01');
            // Crear un objeto Date con la fecha de pago
            var paymentDate = new Date(payment.date);
            var amount = 0;
            for (var i = 0; i < payment.charges.length; i++) {
                amount = amount + payment.charges[i].abonado;
                if ((paymentDate < deadline && payment.charges[i].consecutive === "null") || (payment.charges[i].consecutive === "null" && index !== 0) || payment.ammountPayedSaldoFavor>0) {
                    return false;
                }
                if(amount>payment.ammount && paymentDate < deadline){
                    return false;
                }
            }
            return true;
        };

        vm.delete = function (payment, index) {
            if (!vm.verifyPaymentAbleToDelete(payment, index)) {
                Modal.dialog("No es posible anular este pago. Debe de generar una factura de ajuste en caso que necesite anularlo.", "Para mantener la integridad de datos contables se limita la reversión de pagos que aplicaron algún saldo a favor que puedan haber cancelado una factura posterior. Para reversarlo puede generar una factura de ajuste con el monto y descripción que corresponda o eliminar todos los pagos posteriores a este.", "Entedido");
            } else {
                var id = CommonMethods.encryptIdUrl(payment.id);
                $state.go("houseAdministration.paymentsPerHouse.delete",{paymentId:id});
                // Modal.confirmDialog("¿Está seguro que desea eliminar el pago realizado?", "Una vez eliminado todas las cuotas canceladas en el mismo volverán a ser vigentes.", function () {
                //     Modal.showLoadingBar();
                //     Payment.delete({id: payment.id}, function () {
                //         House.get({
                //             id: payment.houseId
                //         }, onSuccess);
                //         function onSuccess(house) {
                //             Modal.hideLoadingBar();
                //             Modal.toast("La pago se ha eliminado correctamente.");
                //             $rootScope.houseSelected = house;
                //             $localStorage.houseSelected = house;
                //             loadAll();
                //         }
                //     });
                // });
            }
        };
        vm.detailPayment = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('payment-detail', {
                id: encryptedId
            });
        };

        vm.edit = function (payment) {
            var encryptedId = CommonMethods.encryptIdUrl(payment.id);
            $state.go('payment-edit', {
                id: encryptedId
            });
        };
        vm.itemsPerPage = 500;
        vm.initialTime = {
            date: '',
            openCalendar: false
        };
        vm.finalTime = {
            date: '',
            openCalendar: false
        };
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false,
        };
        vm.openCalendar = openCalendar;
        vm.filtering = false;
        vm.print = function (paymentId) {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                });
            }, 8000);
            printJS({
                printable: '/api/payments/file/' + paymentId,
                type: 'pdf',
                modalMessage: "Obteniendo comprobante de pago"
            });
        };

        vm.download = function () {
            vm.exportActions.downloading = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.downloading = false;
                });
            }, 8000);
        };

        vm.sendEmail = function (payment) {

            Modal.confirmDialog("¿Está seguro que desea enviarle el comprobante del pago " + payment.receiptNumber + " al contacto principal de la filial " + $localStorage.houseSelected.housenumber + "?", "",
                function () {
                    vm.exportActions.sendingEmail = true;
                    var thereIs = $localStorage.houseSelected.principalContact != null;
                    if (thereIs === true) {
                        Payment.sendPaymentEmail({
                            paymentId: payment.id
                        });
                        setTimeout(function () {
                            $scope.$apply(function () {
                                vm.exportActions.sendingEmail = false;
                            });
                            Modal.toast("Se ha enviado el comprobante por correo al contacto principal.");
                        }, 8000);
                    } else {
                        vm.exportActions.sendingEmail = false;
                        Modal.toast("Esta filial no tiene un contacto principal para enviarle el correo.");
                    }

                    function onError() {
                        Modal.toast("Esta filial no tiene un contacto principal para enviarle el correo.");
                    }
                });
        };

        vm.cleanSearch = function () {
            vm.isReady = false;
            vm.initialTime = {
                date: undefined,
                openCalendar: false
            };
            vm.finalTime = {
                date: undefined,
                openCalendar: false
            };
            vm.updatePicker();
            vm.filtering = false;
            loadAll();
        };

        vm.filter = function () {
            vm.isReady = false;
            vm.filtering = true;
            loadAll();
        };

        vm.updatePicker = function () {
            vm.picker1 = {
                datepickerOptions: {
                    maxDate: vm.initialTime.date == undefined ? new Date() : vm.finalTime.date,
                    enableTime: false,
                    showWeeks: false,
                }
            };
            vm.picker2 = {
                datepickerOptions: {
                    minDate: vm.initialTime.date,
                    enableTime: false,
                    showWeeks: false,
                }
            };
        };


        vm.updatePicker();


        $('.dating').keypress(function (e) {
            return false;
        });


        $scope.$watch(function () {
            return $rootScope.houseSelected;
        }, function () {
            vm.isReady = false;
            vm.filtering = false;
            vm.initialTime = {
                date: undefined,
                openCalendar: false
            };
            vm.finalTime = {
                date: undefined,
                openCalendar: false
            };
            houseId = $localStorage.houseSelected.id;
            loadAll();
        });

        function openCalendar(time) {
            time.openCalendar = true;
        }

        function loadAll() {
            if (vm.initialTime.date != undefined && vm.filtering == true || vm.finalTime.date != undefined && vm.filtering == true) {
                Payment.getByHouseFilteredByDate({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    initial_time: moment(vm.initialTime.date).format(),
                    final_time: moment(vm.finalTime.date).format(),
                    houseId: CommonMethods.encryptS(houseId),
                    deleted: vm.deleted
                }, onSuccess, onError);
            } else {
                Payment.getByHouse({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    houseId: CommonMethods.encryptS(houseId),
                    deleted: vm.deleted
                }, onSuccess, onError);
            }

            function sort() {
                var result = [];
                if (vm.predicate !== 'date') {
                    result.push('date,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.payments = data;
                angular.forEach(vm.payments, function (payment, i) {
                    payment.isShowingCharges = false;
                });
                vm.page = pagingParams.page;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        vm.showCharges = function (payment) {
            payment.isShowingCharges = !payment.isShowingCharges;
            angular.forEach(vm.payments, function (paymentIn, i) {
                if (paymentIn.id != payment.id) {
                    paymentIn.isShowingCharges = false;
                }
            });
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
