(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('SecurityMobileMenuController', SecurityMobileMenuController);

        SecurityMobileMenuController.$inject = ['CompanyConfiguration', 'globalCompany', 'GateAccess', '$timeout', '$scope', '$stateParams', '$rootScope', '$state', '$translate'];

        function SecurityMobileMenuController(CompanyConfiguration, globalCompany, GateAccess, timeout, $scope, $stateParams, $rootScope, $state, translate) {
            var vm = this;
            $rootScope.active = "security-mobile-menu";
            $rootScope.mainTitle = translate.instant("aditumApp.mobileMenu.security.title"); //"Seguridad";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                $state.go(sref)
            }

            vm.qrCode = false;

            vm.menu = [
                {
                    title: translate.instant("aditumApp.mobileMenu.security.reportGuest"), //"Reportar invitado",
                    icon: "person_add_alt",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "register-visitor-mobile-menu",
                    show: true,
                    class: "animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant("aditumApp.mobileMenu.security.notesSent"), //"Notas enviadas a oficial",
                    icon: "forward_to_inbox",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "user-notes",
                    show: true,
                    class: "animate__animated animate__backInLeft "
                },
                {
                    title: translate.instant("aditumApp.mobileMenu.security.authorizations"), //"Autorizaciones caseta",
                    icon: "flaky",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitor-authorization-request",
                    show: true,
                    class: "animate__animated animate__backInLeft"
                },
                // {
                //     title: "Reportar reunión",
                //     icon: "group_add",
                //     authoritites: "ROLE_USER,ROLE_OWNER",
                //     uisref: "visitant-invited-user.new-list",
                //     show: true,
                //     class: "animate__animated animate__backInRight"
                // },
                {
                    title: translate.instant("aditumApp.mobileMenu.security.reportEmergency"), //"Reportar emergencia",
                    icon: "local_fire_department",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "emergency.new",
                    show: true,
                    class: "animate__animated animate__backInLeft"
                },
                {
                    title: !$rootScope.company.fullControlAccess ? translate.instant("aditumApp.mobileMenu.security.myGuests") : translate.instant("aditumApp.mobileMenu.security.myAuthorized"),
                    icon: "hail",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-user",
                    show: true,
                    class: "animate__animated animate__backInLeft"
                },
                {
                    title: translate.instant("aditumApp.mobileMenu.security.visitants"),
                    icon: "follow_the_signs",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant",
                    show: true,
                    class: "animate__animated  animate__backInRight"
                },
                {
                    title: translate.instant("aditumApp.mobileMenu.security.securityKey"), //"Clave de seguridad",
                    icon: "vpn_key",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "keysConfiguration",
                    show: true,
                    class: "animate__animated  animate__backInLeft"
                }
            ]

            CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
                vm.companyConfig = data;
                if (vm.companyConfig.hasAditumGate && vm.companyConfig.hasQrCodeGate) {
                    GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                        if (data.length != 0 && globalCompany.getId()!=125) {
                            vm.qrCode = true;
                            vm.menu.push({
                                title: translate.instant("aditumApp.mobileMenu.security.generatePersonalQR"), //"Generar QR Personal",
                                icon: "qr_code_scanner",
                                authoritites: "ROLE_USER,ROLE_OWNER",
                                uisref: "select-qr-code",
                                show: true,
                                class: "animate__animated  animate__backInRight"
                            })
                        }
                    })
                }
            })

        }
    }

)();
