(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('CommonAreaAllReservationsResidentViewController', CommonAreaAllReservationsResidentViewController);

    CommonAreaAllReservationsResidentViewController.$inject = ['$translate','$scope', 'AditumStorageService', 'PaymentProof', 'Modal', '$state', 'CommonAreaReservations', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'CommonArea', 'House', 'Resident', '$rootScope', 'CommonMethods', 'globalCompany'];

    function CommonAreaAllReservationsResidentViewController(translate,$scope, AditumStorageService, PaymentProof, Modal, $state, CommonAreaReservations, ParseLinks, AlertService, paginationConstants, pagingParams, CommonArea, House, Resident, $rootScope, CommonMethods, globalCompany) {

        var vm = this;
        $rootScope.active = "allReservationsResidentsView";
        vm.reverse = true;
        vm.loadPage = loadPage;

        $rootScope.mainTitle = translate.instant("aditumApp.commonareaallreservationsresidentview.title");
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.finalListReservations = [];
        moment.locale("en");
        vm.consult = consult;
        vm.isReady = false;
        vm.isConsulting = false;
        vm.itemsPerPage = 20;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        var file;


        function makeid(length, fileName) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        function upload(reservation) {
            var fileName = makeid(4, file.name) + " - " + vm.fileName;
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/payment-proof/' + fileName).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    reservation.paymentProof = downloadURL;
                    vm.paymentProof = {};
                    vm.paymentProof.imageUrl = downloadURL;
                    vm.paymentProof.houseId = reservation.houseId;
                    vm.paymentProof.status = 1;
                    vm.paymentProof.companyId = globalCompany.getId();
                    vm.paymentProof.registerDate = moment(new Date());
                    vm.paymentProof.subject = "Comprobante de pago reservación"
                    PaymentProof.save(vm.paymentProof, function (data) {
                        reservation.paymentProof = data.id
                        if (reservation.id !== null) {
                            CommonAreaReservations.update(reservation, function () {
                                Modal.hideLoadingBar();
                                Modal.toast(translate.instant("aditumApp.commonareaallreservationsresidentview.controller.paymentProofSuccessUpload")) //"Comprobante de pago subido correctamente.")
                            });
                        }
                    });
                });
            });
        }

        vm.uploadPaymentProof = function ($file, reservation) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
                Modal.showLoadingBar();
                upload(reservation);
            }
        };


        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        vm.createReservation = function () {
            $rootScope.backState = $state.current.name;
            $state.go("common-area-reservation-resident-view")
        }
        loadAll();
        vm.detailProof = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $state.go('payment-proof-detail', {
                id: encryptedId
            })
        };

        $(function () {
            var lastScrollTop = 0, delta = 5;
            $(window).scroll(function (event) {
                var st = $(this).scrollTop();

                if (Math.abs(lastScrollTop - st) <= delta)
                    return;

                if (st > lastScrollTop) {
                    // downscroll code
                    console.log('scroll down');
                } else {
                    // upscroll code
                    console.log('scroll up');
                }
                lastScrollTop = st;
            });
        });

        function loadAll() {
            CommonAreaReservations.findByHouseId({
                page: vm.page,
                size: 20,
                sort: sort(),
                houseId: CommonMethods.encryptS(globalCompany.getHouseId())
            }, onSuccess, onError);

            function sort() {
                var result = [];
                if (vm.predicate !== 'initalDate') {
                    result.push('initalDate,desc');
                }
                return result;
            }
        }

        function consult() {
            vm.isConsulting = true;
            vm.isReady = false;
            vm.page = 0;
            vm.finalListReservations = [];
            CommonAreaReservations.findBetweenDatesByHouse({
                initial_time: moment(vm.dates.initial_time).format(),
                final_time: moment(vm.dates.final_time).format(),
                houseId: globalCompany.getHouseId(),
                page: vm.page,
                size: 20,
            }, onSuccess, onError);
        }

        vm.stopConsulting = function () {
            vm.isReady = false;
            vm.dates = {
                initial_time: undefined,
                final_time: undefined
            };
            vm.page = 0;
            vm.finalListReservations = [];
            pagingParams.search = null;
            vm.isConsulting = false;
            vm.showFilterDiv = false;
            loadAll();
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            for (var i = 0; i < data.length; i++) {
                data[i].schedule = formatScheduleTime(data[i].initialTime, data[i].finalTime);
                data[i].initalDate = CommonMethods.setTimezone(new Date(data[i].initalDate));
                vm.finalListReservations.push(data[i])
            }
            vm.isReady = true;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.detailReservation = function (finalListReservations) {
            $state.go('common-area-reservations-detail-resident-view', {
                id: finalListReservations.id
            })
        }
        vm.cancelReservation = function (reservation) {
            if ((globalCompany.getId() == 99 || globalCompany.getId() == 100 || globalCompany.getId() == 102) && reservation.status!=1 && reservation.commonArea.chargeRequired==1) {
                   Modal.toast(translate.instant("aditumApp.commonareaallreservationsresidentview.controller.cancellationNotAllowedAfterPayment")) //"Ya no es posible cancelar la reserva una vez se realizó el pago y se aprobó la reserva.")
            } else {
                Modal.confirmDialog(translate.instant("aditumApp.commonareaallreservationsresidentview.controller.confirmCancellationQuestion"), translate.instant("aditumApp.commonareaallreservationsresidentview.controller.confirmCancellationNote"),
                    function () {
                        Modal.showLoadingBar()
                        reservation.sendPendingEmail = false;
                        reservation.status = 10;
                        reservation.initalDate = new Date(reservation.initalDate)
                        reservation.initalDate.setHours(0);
                        reservation.initalDate.setMinutes(0);
                        CommonAreaReservations.update(reservation, onCancelSuccess);
                    });
            }
        };


        vm.defineIfAbleToCancel = function (reservation) {
            if (reservation.comments != null) {
                return !(reservation.comments.split(translate.instant("aditumApp.commonareaallreservationsresidentview.controller.autoReservationForBooking")).length > 1)
            }
            return true;
        }

        function onCancelSuccess(result) {
            Modal.hideLoadingBar();
            vm.isReady = false;
            vm.finalListReservations = [];
            Modal.toast(translate.instant("aditumApp.commonareaallreservationsresidentview.controller.reservationCancelledSuccessfully")) //"Se ha cancelado la reservación correctamente.")
            loadAll();

        }

        function esEntero(numero) {
            if (numero % 1 == 0) {
                return true;
            } else {
                return false;
            }
        }

        function formatScheduleTime(initialTime, finalTime) {
            var times = [];
            times.push(initialTime);
            times.push(finalTime);
            angular.forEach(times, function (value, key) {
                if (value == 0) {
                    times[key] = "12:00AM"
                } else if (value < 12) {
                    if (esEntero(parseFloat(value))) {
                        times[key] = value + ":00AM"
                    } else {
                        var time = value.split(".")[0];
                        var min = value.split(".")[1];
                        if (min == 75) {
                            min = 45;
                        }
                        if (min == 5) {
                            min = 30;
                        }
                        times[key] = time + ":" + min + "AM";
                    }
                } else if (value > 12) {
                    if (esEntero(parseFloat(value))) {
                        times[key] = value - 12 + ":00PM"
                    } else {
                        var time = value.split(".")[0];
                        var min = value.split(".")[1];
                        if (min == 75) {
                            min = 45;
                        }
                        if (min == 5) {
                            min = 30;
                        }
                        times[key] = time - 12 + ":" + min + "PM";
                    }
                } else if (value == 12) {
                    times[key] = value + ":00PM"
                }
            });
            return times[0] + " - " + times[1]
        }


        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
