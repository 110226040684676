(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PaymentProofAdministrationController', PaymentProofAdministrationController);

    PaymentProofAdministrationController.$inject = ['$translate','AlertService','$rootScope'];

    function PaymentProofAdministrationController(translate, AlertService,$rootScope) {
        $rootScope.active = "paymentProof";
        $rootScope.mainTitle = translate.instant("aditumApp.financesResidentAccount.paymentProof.title");
    }
})();
