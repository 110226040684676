(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('DocumentationMobileMenuController', DocumentationMobileMenuController);

        DocumentationMobileMenuController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany','$state', '$translate'];

        function DocumentationMobileMenuController(timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany,$state, $translate) {
            var vm = this;
            $rootScope.active = "documentation-mobile-menu";
            $rootScope.mainTitle = $translate.instant("aditumApp.mobileMenu.documents.title"); //"Documentos";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name
                $state.go(sref)
            }
            vm.showContracts = function () {
                if ($rootScope.companyUser != undefined) {
                    if ($rootScope.companyUser.type <= 2 && globalCompany.getId() > 1) {
                        return {
                            title: $translate.instant('aditumApp.mobileMenu.documents.contracts'), //"Contratos",
                            icon: "badge",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            uisref: "contract",
                            show: $rootScope.adminCompany.id == 1,
                            class:"animate__animated  animate__backInRight",
                            textLang:"aditumApp.mobileMenu.documents.contracts"
                        }
                    }
                } else {
                    return {};
                }
            };
            vm.menu = [
                {
                    title: $translate.instant('aditumApp.mobileMenu.documents.rules'), //"Reglamentos",
                    icon: "gavel",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "regulation",
                    show: true,
                    class:"animate__animated  animate__backInLeft",
                    textLang:"aditumApp.mobileMenu.documents.rules"
                },
                {
                    title: $translate.instant('aditumApp.mobileMenu.documents.minutes'), //"Actas y minutas",
                    icon: "article",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "condominium-record",
                    show: true,
                    class:"animate__animated  animate__backInRight",
                    textLang:"aditumApp.mobileMenu.documents.minutes"
                },
                {
                    title:  $translate.instant('aditumApp.mobileMenu.documents.others'), //"Otros",
                    icon: "description",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "minutes",
                    show: true,
                    class:"animate__animated  animate__backInLeft",
                    textLang:"aditumApp.mobileMenu.documents.others"
                },
                vm.showContracts(),
            ]


        }
    }

)();
