(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('NoteDialogController', NoteDialogController);

    NoteDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'Note', 'Principal', 'WSNote', '$rootScope', '$state', 'globalCompany', 'Modal', '$translate'];

    function NoteDialogController($timeout, $scope, $stateParams, Note, Principal, WSNote, $rootScope, $state, globalCompany, Modal, translate) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.save = save;
        $rootScope.active = "reportHomeService";
        $rootScope.mainTitle = translate.instant("aditumApp.note.new.sendnewnote");
        Modal.enteringForm(save);
        $scope.$on("$destroy", function () {
            Modal.leavingForm();
        });

        function populateValidNote() {
            vm.note.creationdate = moment(new Date()).format();
            vm.note.companyId = globalCompany.getId();
            vm.note.notetype = 1;
            vm.note.deleted = 0;
            vm.note.status = 1;
            vm.note.houseId = globalCompany.getHouseId();
        }

        function save() {
            Modal.confirmDialog(translate.instant("aditumApp.note.new.question"),"",function(){
                vm.isSaving = true;
                Modal.showLoadingBar();
                if (vm.note.id !== null) {
                    populateValidNote()
                    vm.note.createdByUser = 1;
                    Note.save(vm.note, onSaveSuccess);
                }
            })
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:noteUpdate', result);
            Modal.hideLoadingBar();
            Modal.toast(translate.instant("aditumApp.note.new.successendnote"));
            $state.go('user-notes');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
