(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        $stateProvider
            .state('home-mobile-menu', {
                parent: 'entity',
                url: '/home-mobile-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/home-mobile-menu.html',
                        controller: 'HomeMobileMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
            .state('common-area-administration-mobile-menu', {
                parent: 'entity',
                url: '/common-area-reservations-mobile-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'CommonAreaReservationMobileMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
            .state('security-mobile-menu', {
                parent: 'entity',
                url: '/security-mobile-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'SecurityMobileMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
            .state('select-qr-code', {
                parent: 'entity',
                url: '/select-qr-code',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'GenerateQrCodeController',
                        controllerAs: 'vm',
                    }
                },
            })
            .state('generate-qr-code', {
                parent: 'entity',
                url: '/generate-qr-code/:type',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/aditum-gate/aditum-qr-code-resident.html',
                        controller: 'GeneratePersonalQrController',
                        controllerAs: 'vm',
                    }
                },
            })
            .state('finances-mobile-menu', {
                parent: 'entity',
                url: '/finances-mobile-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'FinancesMobileMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
            .state('administrative-mobile-menu', {
                parent: 'entity',
                url: '/administrative-mobile-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'AdministrativeMobileMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
            .state('documentation-mobile-menu', {
                parent: 'entity',
                url: '/documentation-mobile-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'DocumentationMobileMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
            .state('register-visitor-mobile-menu', {
                parent: 'entity',
                url: '/register-visitor-menu',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER','ROLE_MANAGER'],
                    pageTitle: 'aditumApp.commonAreaReservations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/mobile-menu/items-mobile-menu.html',
                        controller: 'RegisterVisitorMenuController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('mobileMenu');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
