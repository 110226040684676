(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EmergencyDialogController', EmergencyDialogController);

    EmergencyDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'Principal', 'WSEmergency', '$rootScope', 'Modal','globalCompany', '$translate'];

    function EmergencyDialogController($timeout, $scope, $state, $stateParams, Principal, WSEmergency, $rootScope, Modal,globalCompany, translate) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        $rootScope.mainTitle =  translate.instant('aditumApp.emergency.home.reportEmergency') //"Reportar emergencia"
        vm.reportEmergency = save;
        $rootScope.active = "reportemergencyactive";
        vm.enCamino = undefined;
        vm.emergency = {};
        vm.emergencyTypes = [
            { type: translate.instant('aditumApp.emergency.new.fire'), selected: false },
            { type: translate.instant('aditumApp.emergency.new.physicalAssaults'), selected: false },
            { type: translate.instant('aditumApp.emergency.new.healthIssues'), selected: false },
            { type: translate.instant('aditumApp.emergency.new.lifeAtRisk'), selected: false },
            { type: translate.instant('aditumApp.emergency.new.other'), selected: false }
        ];
        setTimeout(function () {
            var emergencyCode = globalCompany.getHouseId();
            WSEmergency.subscribeAttended(emergencyCode);
            WSEmergency.receiveAttented(emergencyCode).then(null, null, emergencyAttended);
            $rootScope.$on('$stateChangeStart',
                function (event, toState, toParams, fromState, fromParams) {
                    WSEmergency.unsubscribeAttended(emergencyCode);
                });
        }, 1000)

        function formatValidEmergency() {

            vm.emergency.companyId = globalCompany.getId();
            vm.emergency.houseId = globalCompany.getHouseId();
            vm.emergency.houseNumber = $rootScope.houseSelected.housenumber;
            vm.emergency.isAttended = 0;
            vm.emergency.reportedDate = moment(new Date).format();
        }

        function save() {
            Modal.confirmDialog(translate.instant('aditumApp.emergency.new.questionModal'), "", function() {
                formatValidEmergency();
                WSEmergency.sendActivity(vm.emergency);
                Modal.toast(translate.instant('aditumApp.emergency.new.emergencyReported'));
                setTimeout(function () {
                    Modal.toast(translate.instant('aditumApp.emergency.new.emergencyModalTwo'));
                }, 3000);
                vm.emergency = {};
            });
        }

        vm.selectEmergency = function(emergency){
            angular.forEach(vm.emergencyTypes, function (value, key) {
                value.selected = false;
            });
            emergency.selected = true;
            vm.emergency.tipo = emergency.type;
        }

        vm.closeIt = function () {
            $('#calma').fadeOut(300)
            setTimeout(function () {
                $('#normal').fadeIn(300)
            }, 300)
        }

        function emergencyAttended(emergency) {
            $('#normal').fadeOut(300)
            setTimeout(function () {
                $('#calma').fadeIn(300)
            }, 300)
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:emergencyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
