(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AdministrationContactController', AdministrationContactController);

    AdministrationContactController.$inject = ['CommonMethods', 'Modal', 'globalCompany', '$rootScope', '$state', 'AdministrationContact', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$translate'];

    function AdministrationContactController(CommonMethods, Modal, globalCompany, $rootScope, $state, AdministrationContact, ParseLinks, AlertService, paginationConstants, pagingParams, $translate) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.isReady = false;

        $rootScope.active = "administration-contact";
        $rootScope.mainTitle = $translate.instant("aditumApp.administrationContact.home.title"); //"Contactos Admin."

        loadAll();

        vm.deleteContact = function (contact) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el contacto?", "", function () {
                AdministrationContact.delete({id: contact.id},
                    function () {
                        Modal.toast("Eliminado");
                        loadAll();
                    });
            })
        }

        vm.editContact = function (contact) {
            var encryptedId = CommonMethods.encryptIdUrl(contact.id)
            $state.go('administration-contact.edit', {
                id: encryptedId
            })
        }

        function loadAll() {

            AdministrationContact.getByCompanyId({
                page: pagingParams.page - 1,
                size: 200,
                sort: sort(),
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.administrationContacts = data;
                vm.page = pagingParams.page;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
