(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('common-area-administration', {
                parent: 'entity',
                url: '/common-area-administration',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_JD'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/common-area/common-area-administration.html',
                        //  templateUrl: 'app/entities/company/commingSoon.html',

                        controller: 'CommonAreaAdministrationController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('common-area-resident-account', {
                parent: 'entity',
                url: '/common-area-resident-account?page&sort&search',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER'],
                    pageTitle: 'aditumApp.commonArea.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/common-area/common-area-resident-account.html',
                        //   templateUrl: 'app/entities/company/commingSoon.html',

                        controller: 'CommonAreaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commonArea');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('common-area-administration.common-area', {
                parent: 'common-area-administration',
                url: '/common-area?page&sort&search',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.commonArea.home.title'
                },

                templateUrl: 'app/entities/common-area/common-areas.html',
                //   templateUrl: 'app/entities/company/commingSoon.html',

                controller: 'CommonAreaController',
                controllerAs: 'vm',

                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commonArea');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('reservation-calendar-resident-view', {
                parent: 'entity',
                url: '/{id}/reservation-calendar-resident',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_OWNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/common-area/reservation-calendar-resident-view.html',
                        controller: 'ReservationCalendarResidentViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function ($stateParams, CommonArea) {
                        return CommonArea.get({id: $stateParams.id}).$promise;
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'reservation-calendar-resident-view',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                                                $translatePartialLoader.addPart('commonArea');
                                                                $translatePartialLoader.addPart('global');
                                                                return $translate.refresh();
                                                            }]
                }
            })
            .state('common-area', {
                url: '/common-area?page&sort&search',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'aditumApp.commonArea.home.title'
                },
                templateUrl: 'app/entities/common-area/common-areas.html',
                //  templateUrl: 'app/entities/company/commingSoon.html',

                controller: 'CommonAreaController',
                controllerAs: 'vm',
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commonArea');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('common-area-administration.new', {
                url: '/newcommonArea',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                templateUrl: 'app/entities/common-area/common-area-dialog.html',
                //    templateUrl: 'app/entities/company/commingSoon.html',
                controller: 'CommonAreaDialogController',
                controllerAs: 'vm',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            description: null,
                            reservationCharge: null,
                            devolutionAmmount: null,
                            chargeRequired: null,
                            reservationWithDebt: null,
                            picture: null,
                            pictureContentType: null,
                            maximunHours: null,
                            id: null,
                            limitActiveReservations: 1,
                            limitPeoplePerReservation: 1,
                            hasDaysBeforeToReserve: 0,
                            hasDefinePeopleQuantity: 0,
                            hasMaximunDaysInAdvance: 0,
                            timesPerDay: 1,
                            hasRelatedArea: 0,
                            askHowUseMoneyCharge: 0,
                            hasMaxCapacity: false,
                            maxCapacity: 0,
                            quantityGuestLimit: 0,
                            hasConditionsToAccept: 0
                        };
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'common-area',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state("common-area-administration.editCommonArea.record-file-preview", {
                parent: 'common-area-administration.editCommonArea',
                url: '/file-preview-common-area-conditions/{url}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/common-area/file-preview-common-area-conditions.html',
                        controller: 'FilePreviewCommonAreaConditionsController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('common-area-administration.editCommonArea', {
                url: '/{id}/editCommonArea',
                data: {
                    authorities: ['ROLE_MANAGER']
                },

                templateUrl: 'app/entities/common-area/common-area-dialog.html',
                //   templateUrl: 'app/entities/company/commingSoon.html',

                controller: 'CommonAreaDialogController',
                controllerAs: 'vm',
                resolve: {
                    entity: function ($stateParams, CommonArea, CommonMethods) {
                        var id = CommonMethods.decryptIdUrl($stateParams.id);
                        return CommonArea.get({id: id}).$promise;
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'common-area',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                            $translatePartialLoader.addPart('commonArea');
                                            $translatePartialLoader.addPart('global');
                                            return $translate.refresh();
                                        }]
                }
            })

            .state('common-area-administration.reservation-calendar', {
                url: '/{id}/reservation-calendar',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                templateUrl: 'app/entities/common-area/reservation-calendar.html',
                //    templateUrl: 'app/entities/company/commingSoon.html',

                controller: 'ReservationCalendarController',
                controllerAs: 'vm',
                resolve: {
                    entity: function ($stateParams, CommonArea) {
                        return CommonArea.get({id: $stateParams.id}).$promise;
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'common-area',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                            $translatePartialLoader.addPart('commonArea');
                                            $translatePartialLoader.addPart('global');
                                            return $translate.refresh();
                                        }]
                }
            })

            .state('common-area-administration.general-reservation-calendar', {
                url: '/general-reservation-calendar',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                templateUrl: 'app/entities/common-area/general-reservation-calendar.html',
                controller: 'GeneralReservationCalendarController',
                controllerAs: 'vm',
            });
    }

})();
