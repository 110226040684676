(function () {
    'use strict';

    angular
            .module('aditumApp')
            .controller('HomeMobileMenuController', HomeMobileMenuController);

    HomeMobileMenuController.$inject = ['TokenNotifications','$mdDialog', 'Auth', '$cookies', 'WSVisitorAuthorizationRequest', '$timeout', 'VisitorAuthorizationRequest', '$localStorage', 'House', 'CompanyConfiguration', 'Modal', 'AditumGate', 'GateAccess', '$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany', '$state', 'WSGate', 'Principal', 'HouseLoginTracker', '$translate'];

    function HomeMobileMenuController(TokenNotifications,$mdDialog, Auth, $cookies, WSVisitorAuthorizationRequest, $timeout, VisitorAuthorizationRequest, $localStorage, House, CompanyConfiguration, Modal, AditumGate, GateAccess, timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany, $state, WSGate, Principal, HouseLoginTracker, translate) {
        var vm = this;
        $rootScope.mainTitle = translate.instant("aditumApp.mobileMenu.title"); //"Inicio";
        $rootScope.active = "home-mobile-menu";
        vm.isReady = false;
        vm.loadedMenu = false;
        vm.menuGateShow = false;
        vm.executed = false;
        vm.menuGate = {};
        vm.authorities = $localStorage.houseSelected.roleResident;
        vm.menuGate.show = false;
        vm.showVisitorAuthorization = false;
        var timer;
        vm.fcmToken = $cookies.get("FCM_TOKEN");
        vm.companyId = globalCompany.getId();

        Principal.identity().then(function (account) {
            if (account !== null) {
                var isUser = account.authorities[0] == "ROLE_USER" || account.authorities[0] == "ROLE_OWNER";
                vm.isUser = isUser;
                if (isUser) {
                    vm.houseLoginTracker = {
                        lastTime: moment(new Date()).format(),
                        user: account.id,
                        companyId: globalCompany.getId(),
                        id: null,
                        houseId: globalCompany.getHouseId()
                    };
                    HouseLoginTracker.save(vm.houseLoginTracker, function () {
                    });
                }
            }
        }
            );

        if ($localStorage.menu2HomeMobile) {
            vm.menu2 = $localStorage.menu2HomeMobile;
        }
        if ($localStorage.menuGates) {
            vm.menuGates = $localStorage.menuGates;
        }

        if ($localStorage.menu1HomeMobile) {
            vm.menu = $localStorage.menu1HomeMobile;
                // vm.isReady = true;
        }

        function logout() {
            Auth.logout();
            $localStorage.houseSelected = undefined;
            $rootScope.companyUser = undefined;
            $state.go('home');
            $rootScope.menu = false;
            $rootScope.companyId = undefined;
            $localStorage.companyName = undefined;
            $rootScope.companyConfigsLoaded = false;
            $rootScope.showLogin = true;
            $rootScope.inicieSesion = false;
        }

        vm.showAdvanced = function () {
            $mdDialog.show({
                templateUrl: 'app/components/login/privacy-terms.html',
                controller: DialogController,
                parent: angular.element(document.body),
                clickOutsideToClose: false,
            }).then(function (answer) {
                $localStorage.acceptedConditions = "ACCEPTED";
            }, function () {
                $localStorage.acceptedConditions = "ACCEPTED";
                    // logout();
            });
        };

        if ($localStorage.acceptedConditions != "ACCEPTED") {
                // vm.showAdvanced();
        }

        function DialogController($scope, $mdDialog) {
            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.answer = function (answer) {
                $mdDialog.hide(answer);
            };
        }

        function onEnter() {
            if (document.visibilityState == "visible" && vm.counter == 45) {
                $timeout.cancel(timer);
                $timeout(function () {
                    vm.findAuthorization();
                }, 100);
            }
        }

        document.addEventListener("visibilitychange", onEnter);

        $scope.$on('$destroy', function () {
            document.removeEventListener("visibilitychange", onEnter);
        });

        vm.findAuthorization = function () {
            VisitorAuthorizationRequest.findNew({houseId: globalCompany.getHouseId()}, function (data) {
                vm.setAuthorization(data);
            });
        };

        $timeout(function () {
            WSVisitorAuthorizationRequest.subscribe(globalCompany.getHouseId());
            $timeout(function () {
                WSVisitorAuthorizationRequest.receive(globalCompany.getHouseId()).then(null, null, receiveAuthorization);
            }, 500);
        }, 2000);

        function receiveAuthorization(data) {
            $timeout(function () {
                $scope.$apply(function () {
                    $timeout.cancel(timer);
                    vm.setAuthorization(data);
                });
            });
        }

        vm.acceptAuthorization = function () {
            Modal.confirmDialog(translate.instant("aditumApp.mobileMenu.visitorAuthorization.confirmation.title"), translate.instant("aditumApp.mobileMenu.visitorAuthorization.confirmation.content"), function () {
                vm.visitorAuthorization.status = 1;
                vm.visitorAuthorization.residentId = $rootScope.companyUser.id;
                WSVisitorAuthorizationRequest.sendActivityResponse(vm.visitorAuthorization, globalCompany.getHouseId());
                vm.showVisitorAuthorization = false;
                Modal.toast(translate.instant("aditumApp.mobileMenu.visitorAuthorization.confirmation.resultMessage"));
            });
        };
        vm.declineAuthorization = function () {
            Modal.confirmDialog(translate.instant("aditumApp.mobileMenu.visitorAuthorization.confirmationDecline.title"), translate.instant("aditumApp.mobileMenu.visitorAuthorization.confirmationDecline.content"), function () {
                vm.visitorAuthorization.status = 2;
                vm.visitorAuthorization.residentId = $rootScope.companyUser.id;
                WSVisitorAuthorizationRequest.sendActivityResponse(vm.visitorAuthorization, globalCompany.getHouseId());
                vm.showVisitorAuthorization = false;
                Modal.toast(translate.instant("aditumApp.mobileMenu.visitorAuthorization.confirmationDecline.resultMessage"));
            });

        };

        vm.setAuthorization = function (data) {
            vm.visitorAuthorization = data;
            vm.counter = vm.visitorAuthorization.secondsAgo;
            if (vm.counter <= 0) {
                vm.cancelCountDown();
            } else {
                vm.countDown();
                vm.showVisitorAuthorization = true;
            }
        };


        vm.countDown = function () {
            timer = $timeout(function () {
                --vm.counter;
                if (vm.counter > -1) {
                    vm.countDown();
                } else {
                    vm.cancelCountDown();
                }
            }, 1000);
        };

        vm.cancelCountDown = function () {
            $timeout.cancel(timer);
            if (vm.showVisitorAuthorization) {
                vm.counter = 45;
                vm.showVisitorAuthorization = false;
                vm.visitorAuthorization.status = 3;
                VisitorAuthorizationRequest.update(vm.visitorAuthorization, function () {
                });
            }
        };

        vm.findAuthorization();


        House.get({id: globalCompany.getHouseId()}, function (data) {
            if (vm.fcmToken != undefined) {

                TokenNotifications.login({userId: account.id, token: fcmToken}, function () {
                });
                if (data.allowAditumGate == 1) {
                    CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
                        vm.companyConfig = data;
                        if (vm.companyConfig.hasAditumGate && vm.companyConfig.allowBtnOpenDoor) {
                            GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                                if (data.length > 0) {
                                    vm.menuGate = {
                                        title: translate.instant("aditumApp.mobileMenu.visitorAuthorization.accessDoor"), //"Puertas de acceso",
                                        icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fdoor-e4ac1b6781.svg?alt=media&token=663000e1-c2bd-4e5e-8577-75551b5d91a6",
                                        authoritites: "ROLE_USER,ROLE_OWNER",
                                        uisref: "emergency.new",
                                        show: true,
                                        class: "animate__animated  animate__backInLeft"
                                    };
                                    vm.menuGates = data[0].gates;
                                    vm.menuGate.show = true;
                                    vm.accesDoorId = data[0].id;
                                    $localStorage.menuGates = vm.menuGates;
                                } else {
                                    $localStorage.menuGates = undefined;
                                    vm.menuGate.show = false;
                                }
                            });
                        }
                    });
                }
            }
        });

        function sendWSGateOpen(gate) {
            Principal.identity().then(function (data) {
                var bitacora = {
                    id: null,
                    concept: translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.success.message1") + gate.description + translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.success.actionDoor") + $rootScope.houseSelected.housenumber,
                    type: 30,
                    idResponsable: data.id,
                    category: "Puertas Acceso",
                    houseId: globalCompany.getHouseId(),
                    companyId: globalCompany.getId(),
                    houseNumber: $rootScope.houseSelected.housenumber,
                };
                WSGate.sendActivity(bitacora);
            });
        }


        vm.openGate = function (gate) {
            Modal.confirmDialog("¿Está seguro que desea accionar la puerta?", "" +
                    "Quedará un registro cada vez que la accione.", function () {
                        gate.disable = true;
                        AditumGate.openGate({gateId: gate.id}, function (data) {
                            if (data.result) {
                                Modal.toast(translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.success.message1") + gate.description + translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.success.message2"));
                                gate.movement = true;
                                sendWSGateOpen(gate);
                                setTimeout(function () {
                                    $scope.$apply(function () {
                                        gate.movement = false;
                                        gate.disable = false;
                                    });
                                }, 5000);
                            } else {
                                setTimeout(function () {
                                    $scope.$apply(function () {
                                        gate.movement = false;
                                        gate.disable = false;
                                        Modal.toast(translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.error"));
                                    });
                                }, 1000);
                            }
                        }, function () {
                        });
                    });
        };
        vm.closeGate = function (gate) {
            gate.disable = true;
            AditumGate.closeGate({gateId: gate.id}, function () {
                Modal.toast(gate.description + translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.success.message2"));
                gate.movement = true;
                setTimeout(function () {
                    gate.movement = false;
                    gate.disable = false;
                }, 4000);
            }, function () {
                setTimeout(function () {
                    gate.movement = false;
                    gate.disable = false;
                    Modal.toast(translate.instant("aditumApp.mobileMenu.aditumGate.openConfirmation.error"));
                }, 2000);
            });
        };

        vm.showGateMenu = function () {
            vm.menuGateShow = !vm.menuGateShow;
        };

        function getRandomInt(max) {
            return Math.floor(Math.random() * (max - 0)) + 0;
        }

        vm.showMenuResident = function () {
            if ($localStorage.houseSelected) {
                var role = $localStorage.houseSelected.roleResident;
                if (role == "ROLE_OWNER" || role == "ROLE_TENANT" || role == "ROLE_RESIDENT") {
                    return true;
                }
                return false;
            }
        };

        vm.banners = ["/content/images/banner-morning.jpg", "/content/images/banner-afternoon.jpg", "/content/images/banner-night.jpg"];
        vm.bannersHalloween = ["/content/images/banner-halloween-day.jpg", "/content/images/banner-halloween-afternoon.jpg", "/content/images/banner-halloween-night.jpg"];
        vm.bannerChristmas = ["https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2FNavidad-Dia.jpg?alt=media&token=dee12ef8-7b49-4624-a3b1-c0ece694dc08", "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2FNavidad-Tarde.jpg?alt=media&token=7a0d4adf-52b0-4467-bc20-94621c1b32e0", "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2FNavidad-Noche.jpg?alt=media&token=14078cec-cb8f-46cd-b074-4f1bb3208fb6"];
        vm.bannerNewYear = ["https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2FCopia%20de%20escenarios-deldia-2022%203.jpg?alt=media&token=6cf70487-4bbd-4b42-86e0-de5df0646f6d", "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2FCopia%20de%20escenarios-deldia-2022%202.jpg?alt=media&token=17dc8637-5b69-413c-a3f8-32f7d1d84012", "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2FCopia%20de%20escenarios-deldia-2022.jpg?alt=media&token=49a35c27-8451-4620-b628-9a0f50d5a9e2"];
        vm.bannerNewYearEve = ["https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fescenarios-deldia-fireworks.jpg?alt=media&token=6222fea5-3648-45e6-808c-d58d4ffdb4f2", "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fescenarios-deldia-fireworks.jpg?alt=media&token=6222fea5-3648-45e6-808c-d58d4ffdb4f2", "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fescenarios-deldia-fireworks.jpg?alt=media&token=6222fea5-3648-45e6-808c-d58d4ffdb4f2"];

        vm.grettingsMorning = [translate.instant("aditumApp.mobileMenu.greetings.morning.0"), translate.instant("aditumApp.mobileMenu.greetings.morning.1")];
        vm.grettingsEvenning = [translate.instant("aditumApp.mobileMenu.greetings.evening.0"), translate.instant("aditumApp.mobileMenu.greetings.evening.1")];
        vm.grettingsNight = [translate.instant("aditumApp.mobileMenu.greetings.night.0"), translate.instant("aditumApp.mobileMenu.greetings.night.1")];
        vm.grettingsNightNewYearEve = [translate.instant("aditumApp.mobileMenu.greetings.nightNewYearEve.0"), translate.instant("aditumApp.mobileMenu.greetings.nightNewYearEve.1")];
        vm.phrasesNewYearEve = [translate.instant("aditumApp.mobileMenu.phrasesNewYearEve.0"), translate.instant("aditumApp.mobileMenu.phrasesNewYearEve.1"), translate.instant("aditumApp.mobileMenu.phrasesNewYearEve.2"), translate.instant("aditumApp.mobileMenu.phrasesNewYearEve.3")];

        vm.grettingsNewYear = [translate.instant("aditumApp.mobileMenu.greetings.newYear.0"), translate.instant("aditumApp.mobileMenu.greetings.newYear.0")];
        vm.phrasesNewYear = [];
        vm.phrases = [translate.instant("aditumApp.mobileMenu.phrasesGreetings.0"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.1"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.2"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.3"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.4"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.5"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.6"), translate.instant("aditumApp.mobileMenu.phrasesGreetings.7")];

        vm.go = function (sref) {
            $rootScope.backState = $state.current.name;
            $state.go(sref);
        };

        vm.defineBannerPicture = function () {
            if (vm.isHalloween()) {
                return vm.bannersHalloween;
            }
            if (vm.isChristmas()) {
                if (vm.isNewYearEve()) {
                    return vm.bannerNewYearEve;
                }
                return vm.bannerChristmas;
            }
            if (vm.isNewYear()) {
                return vm.bannerNewYear;
            }
            return vm.banners;
        };

        vm.isHalloween = function () {
            var now = new Date();
            var year = now.getFullYear();
            var timestampMax = Date.parse("10/31/" + year);
            var timestampMin = Date.parse("10/13/" + year);
            var maxHalloweenDate = new Date(timestampMax);
            var minHalloweenDate = new Date(timestampMin);
            var isHalloween = minHalloweenDate.getTime() <= now.getTime() && now.getTime() <= maxHalloweenDate.getTime();
            return isHalloween;
        };

        vm.isChristmas = function () {
            var now = new Date();
            var year = now.getFullYear();
            var timestampMax = Date.parse("12/31/" + year);
            var timestampMin = Date.parse("11/25/" + year);
            var maxHalloweenDate = new Date(timestampMax);
            var minHalloweenDate = new Date(timestampMin);
            maxHalloweenDate.setHours(24);
            var isChristmast = minHalloweenDate.getTime() <= now.getTime() && now.getTime() <= maxHalloweenDate.getTime();
            return isChristmast;
        };
        vm.isNewYearEve = function () {
            var now = new Date();
            var year = now.getFullYear();
            var timestampMax = Date.parse("12/31/" + year);
            var timestampMin = Date.parse("12/31/" + year);
            var maxHalloweenDate = new Date(timestampMax);
            var minHalloweenDate = new Date(timestampMin);
            maxHalloweenDate.setHours(24);
            var isChristmast = minHalloweenDate.getTime() <= now.getTime() && now.getTime() <= maxHalloweenDate.getTime();
            return isChristmast;
        };

        vm.isNewYear = function () {
            var now = new Date();
            var year = now.getFullYear();
            var timestampMax = Date.parse("01/05/" + year);
            var timestampMin = Date.parse("01/01/" + year);
            var maxHalloweenDate = new Date(timestampMax);
            var minHalloweenDate = new Date(timestampMin);
            var isNewYear = minHalloweenDate.getTime() <= now.getTime() && now.getTime() <= maxHalloweenDate.getTime();
            return isNewYear;
        };

        vm.defineBanner = function () {
            vm.bannerNow = vm.defineBannerPicture();
            var now = new Date().getHours();
            if (now >= 5 && now < 13) {
                vm.currentBanner = vm.bannerNow[0];
                vm.getting = vm.grettingsMorning[getRandomInt(vm.grettingsMorning.length)];
                if (now >= 12) {
                    vm.getting = vm.grettingsEvenning[getRandomInt(vm.grettingsEvenning.length)];
                }
            } else if (now >= 13 && now < 18) {
                vm.currentBanner = vm.bannerNow[1];
                vm.getting = vm.grettingsEvenning[getRandomInt(vm.grettingsEvenning.length)];
            } else if (now >= 18 || now < 5) {
                vm.currentBanner = vm.bannerNow[2];
                vm.getting = vm.grettingsNight[getRandomInt(vm.grettingsNight.length)];
            }

            if (vm.isNewYear()) {
                vm.getting = "";
                vm.phrase = "";
            } else if (vm.isNewYearEve()) {
                vm.phrase = vm.phrasesNewYearEve[getRandomInt(vm.phrasesNewYearEve.length)];
            } else {
                vm.phrase = vm.phrases[getRandomInt(vm.phrases.length)];
            }
        };
        vm.defineBanner();
        vm.showingMenu = false;

        $scope.$watch(function () {
            return $rootScope.loadedMenu;
        }, function () {
            if (!vm.showingMenu) {
                $scope.$watch(function () {
                    return $rootScope.companyUser;
                }, function () {
                    if (!vm.executed) {
                        if ($rootScope.companyUser) {
                            vm.executed = true;
                            var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                            vm.bookCommonArea = companyConfig.bookCommonArea;
                            vm.hasControlAccess = companyConfig.hasControlAccess;
                            if (!$localStorage.houseSelected) {
                                $rootScope.houseSelected = $rootScope.companyUser.houses[0];
                                $rootScope.filialNumber = $rootScope.companyUser.houses[0].housenumber;
                                $localStorage.houseId = CommonMethods.encryptIdUrl($rootScope.companyUser.houses[0].id);
                                $localStorage.houseSelected = $rootScope.companyUser.houses[0];
                            } else {
                                $rootScope.houseSelected = $localStorage.houseSelected;
                                $rootScope.filialNumber = $localStorage.houseSelected.housenumber;
                                $localStorage.houseId = CommonMethods.encryptIdUrl($localStorage.houseSelected.id);
                            }
                            CompanyConfiguration.get({id: globalCompany.getId()}, function (result) {
                                if (result.hasContability == 1) {
                                    vm.hasContability = true;
                                    if (companyConfig.tendersWatchWC && $rootScope.houseSelected.roleResident == "ROLE_TENANT") {
                                        vm.isTenderWithWaterCharge = true;
                                        $rootScope.isTenderWithWaterCharge = true;
                                    }
                                } else {
                                    vm.hasContability = false;
                                }
                                setTimeout(function () {
                                    $scope.$apply(function () {
                                        vm.menu = [
                                            {
                                                title: $rootScope.company.fullControlAccess ? translate.instant("aditumApp.mobileMenu.home.reserve") : translate.instant("aditumApp.mobileMenu.home.reserveArea"),
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fevent.svg?alt=media&token=0cef826b-4f21-4ddd-ac9f-afc6edf3475d",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "common-area-reservation-resident-view",
                                                show: vm.bookCommonArea && vm.showMenuResident(),
                                                class: "animate__animated  animate__backInLeft"
                                            },
                                            {
                                                title: $rootScope.company.fullControlAccess ? translate.instant("aditumApp.mobileMenu.home.reportIncome") : translate.instant("aditumApp.mobileMenu.home.reportGuest"),
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fperson_add_alt.svg?alt=media&token=1f467054-f56f-43ba-bfce-d5aa7817ba4d",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "register-visitor-mobile-menu",
                                                show: vm.showMenuResident() && vm.hasControlAccess,
                                                class: "animate__animated  animate__backInRight"
                                            },
                                            {
                                                title: translate.instant('aditumApp.mobileMenu.home.informOfficer'),//  "Informar a oficial",
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fforward_to_inbox.svg?alt=media&token=c3c5edd8-6429-465a-b146-9184deab9650",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "noteNew",
                                                show: vm.showMenuResident() && vm.hasControlAccess,
                                                class: "animate__animated  animate__backInLeft"
                                            },
                                            {
                                                title: translate.instant('aditumApp.mobileMenu.home.accountStatus'), //"Ver mi estado de cuenta",
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Faccount_balance_wallet.svg?alt=media&token=bbc45984-7e32-4f3a-bb84-7f66cd374ce1",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: vm.authorities != "ROLE_TENANT" ? "accountStatus-residentAccount" : "chargePerHouse-residentAccount",
                                                show: (!vm.isTenderWithWaterCharge && vm.hasContability && !$rootScope.company.fullControlAccess),
                                                class: "animate__animated  animate__backInRight"
                                            },
                                            {
                                                title: translate.instant('aditumApp.mobileMenu.home.viewWaterCharges'), //"Ver mis cuotas de agua",
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Faccount_balance_wallet.svg?alt=media&token=bbc45984-7e32-4f3a-bb84-7f66cd374ce1",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "waterChargePerHouse-residentAccount",
                                                show: vm.isTenderWithWaterCharge && vm.hasContability && !$rootScope.company.fullControlAccess,
                                            },
                                        ];
                                        $localStorage.menu1HomeMobile = vm.menu;

                                        vm.menu2 = [
                                            {
                                                title: translate.instant('aditumApp.mobileMenu.home.reportEmergency'), //"Reportar emergencia",
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Flocal_fire.svg?alt=media&token=72c15786-cff6-4565-baa8-a4d025b652c9",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "emergency.new",
                                                show: vm.showMenuResident() && vm.hasControlAccess,
                                                class: "animate__animated  animate__backInLeft"
                                            },
                                            {
                                                title: vm.companyId == 60 || vm.companyId == 91 || $rootScope.company.fullControlAccess ? translate.instant('aditumApp.mobileMenu.home.sendTicket') : translate.instant('aditumApp.mobileMenu.home.sendCondoRequest'),
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Frecord_voice_over.svg?alt=media&token=e1ec4f02-2687-44da-83f7-dde191049379",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "complaint-user.new",
                                                show: true,
                                                class: "animate__animated  animate__backInRight"
                                            },
                                            {
                                                title: translate.instant('aditumApp.mobileMenu.home.sendRequest'),// "Enviar solicitud al administrador",
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fforum.svg?alt=media&token=b5f06ab7-a892-464c-b4e8-c832b1b63c23",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "individual-release-user.new",
                                                show: $rootScope.adminCompany.id == 1 && (vm.companyId != 60 && vm.companyId != 91),
                                                class: "animate__animated  animate__backInUp"
                                            },
                                        ];

                                        $localStorage.menu2HomeMobile = vm.menu2;
                                        vm.isReady = true;
                                    });
                                }, 10);
                            });
                        }
                    }
                });
            }
        }
            );
    }
}

)();
