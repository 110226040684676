(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$mdDialog', 'ActivityResident', 'EmailConfiguration', '$rootScope', '$state', 'Principal', '$timeout', 'Auth', 'MultiCompany', 'House', '$localStorage', 'CommonMethods', 'Modal', 'CompanyConfiguration', 'AdministrationConfiguration', 'MacroCondominium', 'Company', 'globalCompany'];

    function LoginController($mdDialog, ActivityResident, EmailConfiguration, $rootScope, $state, Principal, $timeout, Auth, MultiCompany, House, $localStorage, CommonMethods, Modal, CompanyConfiguration, AdministrationConfiguration, MacroCondominium, Company, globalCompany) {

        var vm = this;
        vm.isIdentityResolved = Principal.isIdentityResolved;
        vm.pdfUrl = 'content/manuals/manualusuario.pdf';
        // vm.loadNewFile = function (url) {
        //     pdfDelegate
        //         .$getByHandle('my-pdf-container')
        //         .load(url);
        // };
        var backImage = "content/images/aditumback.jpg";
        var backImageChristmast = "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fchirstmast-login.jpg?alt=media&token=0204afe0-475c-4933-b98b-330466d4ee64";

        vm.defineBannerPicture = function () {
            if (vm.isChristmas()) {
                return backImageChristmast;
            }
            return backImage;
        };

        vm.isHalloween = function () {
            var now = new Date();
            var year = now.getFullYear();
            var timestampMax = Date.parse("10/31/" + year);
            var timestampMin = Date.parse("10/13/" + year);
            var maxHalloweenDate = new Date(timestampMax);
            var minHalloweenDate = new Date(timestampMin);
            var isHalloween = minHalloweenDate.getTime() <= now.getTime() && now.getTime() <= maxHalloweenDate.getTime();
            return isHalloween;
        };

        vm.isChristmas = function () {
            var now = new Date();
            var year = now.getFullYear();
            var timestampMax = Date.parse("12/26/" + year);
            var timestampMin = Date.parse("11/25/" + year);
            var maxHalloweenDate = new Date(timestampMax);
            var minHalloweenDate = new Date(timestampMin);
            var isChristmast = minHalloweenDate.getTime() <= now.getTime() && now.getTime() <= maxHalloweenDate.getTime();
            return isChristmast;
        };

        vm.anno = moment(new Date()).format('YYYY');
        $rootScope.showLogin = true;
        $rootScope.companyConfigsLoaded = false;
        $rootScope.showSelectCompany = false;
        vm.isChangingPassword = $state.includes('finishReset');
        vm.isResetPassword = $state.includes('requestReset');
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.showLoginHelp = showLoginHelp;
        vm.password = null;
//        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.account = {};
        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function showLoginHelp() {
            Modal.dialog("Acceder al sistema", "Tu nombre de usuario es el correo electrónico con el cual se registró tu cuenta. Sino recuerdas tu contraseña puedes recuperarla dando click en el botón de '¿Olvidaste tu contraseña?'", "¡Entendido!");
        }

        function defineCompanyConfig(companyConfig, administrationConfiguration) {
            return companyConfig.companyId + ";" + companyConfig.hasContability + ";" + companyConfig.minDate + ";" + companyConfig.hasAccessDoor + ";" + administrationConfiguration.incomeStatement + ";" + administrationConfiguration.monthlyIncomeStatement + ";" + administrationConfiguration.bookCommonArea + ";" + administrationConfiguration.initialConfiguration + ";" + companyConfig.hasRounds + ";" + companyConfig.currency + ";" + companyConfig.quantityadmins + ";" + companyConfig.tendersWatchWC + ";" + companyConfig.hasAditumGate + ";" + companyConfig.hasQrCodeGate + ";" + companyConfig.allowBtnOpenDoor + ";" + companyConfig.secondCurrency + "|";
        }


        function loadCompanyConfig(companiesTotal, i, companiesConfigArray, showInitialConfigArray) {
            if (i == companiesTotal) {
                $localStorage.companiesConfig = CommonMethods.encryptIdUrl(companiesConfigArray);
                $localStorage.initialConfig = CommonMethods.encryptIdUrl(showInitialConfigArray);
            }
        }

        function logout() {
            Auth.logout();
            $localStorage.houseSelected = undefined;
            $rootScope.companyUser = undefined;
            $state.go('home');
            $rootScope.menu = false;
            $rootScope.companyId = undefined;
            $localStorage.companyName = undefined;
            $rootScope.companyConfigsLoaded = false;
            $rootScope.showLogin = true;
            $rootScope.inicieSesion = false;
            $localStorage.lang = null;
        }

        function notificationActivityResident(userId) {
            ActivityResident.getNotSeeingByUser({userId: userId, size: 1, page: 0}, function (data) {
                $rootScope.activityResidentNoti = data.length != 0;
            });
        }


        function hideLogin() {
            $rootScope.menu = true;
            $rootScope.showLogin = false;
            $rootScope.inicieSesion = true;
        }


        function login(event) {
            $rootScope.company = undefined;
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function (data) {
                vm.authenticationError = false;
                $localStorage.lang = "es";
                Principal.identity().then(function (account) {
                    $rootScope.account = account;
                    switch (account.authorities[0]) {
                    case "ROLE_ADMIN":
                        hideLogin();
                        $state.go('company');
                        break;
                    case "ROLE_MANAGER":
                    case "ROLE_ACCOUNTANT":
                        MultiCompany.getCurrentUserCompany().then(function (user) {
                            hideLogin();
                            $localStorage.userId = CommonMethods.encryptIdUrl(user.id);
                            $localStorage.userRole = CommonMethods.encryptIdUrl(user.role);
                            $rootScope.companyUser = user;
                            $rootScope.showSelectCompany = false;
                            CommonMethods.setCompanyId(user.companies[0].id);
                            var companiesConfigArray = "";
                            var showInitialConfigArray = "";
                            for (var i = 0; i < user.companies.length; i++) {
                                CompanyConfiguration.get({id: user.companies[i].id}, function (companyConfig) {
                                    $rootScope.currency = companyConfig.currency;
                                    AdministrationConfiguration.get({companyId: companyConfig.companyId}, function (result) {
                                        var administrationConfiguration = result;
                                        companiesConfigArray += defineCompanyConfig(companyConfig, administrationConfiguration);
                                        showInitialConfigArray += companyConfig.companyId + ";" + administrationConfiguration.initialConfiguration + ";" + companyConfig.hasContability + "|";
                                        if (user.companies.length == i) {
                                            vm.backgroundSelectCompany = true;
                                            loadCompanyConfig(user.companies.length, i, companiesConfigArray, showInitialConfigArray);
                                            Company.get({id: globalCompany.getId()}, function (condo) {
                                                vm.contextLiving = condo.name;
                                                $rootScope.companyName = condo.name;
                                                $rootScope.contextLiving = vm.contextLiving;
                                                vm.company = condo;
                                                $rootScope.company = condo;
                                                $rootScope.currentUserImage = user.image_url;
                                                if (user.enabled == 0) {
                                                    logout();
                                                }
                                                        // setTimeout(function(){
                                                $rootScope.companyConfigsLoaded = true;
                                                        // },1000)
                                            });
                                            $state.go('houseAdministration.accountStatus');
                                        }
                                    });
                                });
                            }
                        });
                        break;
                    case "ROLE_MANAGER_MACRO":
                                // MultiCompany.getCurrentUserCompany().then(function (user) {
                                //     $rootScope.companyUser = user;
                                //     $rootScope.showSelectCompany = false;
                                //     var companiesConfigArray = "";
                                //     var showInitialConfigArray = "";
                                //     MacroCondominium.get({id: user.macroCondominiumId}, function (macroCondo) {
                                //         $rootScope.companyUser.companies = macroCondo.companies;
                                //         $localStorage.companyId = CommonMethods.encryptIdUrl(macroCondo.companies[0].id);
                                //         $localStorage.macroCompanyId = CommonMethods.encryptIdUrl(user.macroCondominiumId);
                                //         for (var i = 0; i < macroCondo.companies.length; i++) {
                                //             CompanyConfiguration.get({id: macroCondo.companies[i].id}, function (companyConfig) {
                                //                 $rootScope.currency = companyConfig.currency;
                                //                 AdministrationConfiguration.get({companyId: companyConfig.companyId}, function (result) {
                                //                     var administrationConfiguration = result;
                                //                     companiesConfigArray += defineCompanyConfig(companyConfig,administrationConfiguration);
                                //                     showInitialConfigArray += companyConfig.companyId + ";" + administrationConfiguration.initialConfiguration + "|";
                                //                     if (macroCondo.companies.length == i) {
                                //                         vm.backgroundSelectCompany = true;
                                //                         $localStorage.companiesConfig = CommonMethods.encryptIdUrl(companiesConfigArray);
                                //                         $localStorage.initialConfig = CommonMethods.encryptIdUrl(showInitialConfigArray);
                        $state.go('announcement-user');
                                //                     }
                                //                 });
                                //             })
                                //         }
                                //     })
                                // })
                        break;
                    case "ROLE_OFFICER":
                        MultiCompany.getCurrentUserCompany().then(function (data) {
                            hideLogin();
                            $rootScope.companyUser = data;
                            $localStorage.companyId = CommonMethods.encryptIdUrl(data.companyId);
                            $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_OFFICER");
                            if (data != null) {
                                vm.contextLiving = $rootScope.companyUser.name;
                                $rootScope.contextLiving = vm.contextLiving;
                                $rootScope.currentUserImage = null;
                            }
                            Company.get({id: parseInt(globalCompany.getId())}, function (condo) {
                                vm.contextLiving = condo.name;
                                $rootScope.contextLiving = vm.contextLiving;
                                vm.company = condo;
                                if (condo.active == 0 || data.enable == 0) {
                                    logout();
                                }
                            });
                            $rootScope.hideFilial = true;
                            $state.go('access-door.visitors');
                        });
                        break;
                    case "ROLE_OFFICER_MACRO":
                                // MultiCompany.getCurrentUserCompany().then(function (data) {
                                //     $localStorage.companyId = CommonMethods.encryptIdUrl(data.macroCondominiumId);
                        $state.go('access-door-macro.access');
                            // });
                            // break;
                    case "ROLE_USER":
                        MultiCompany.getCurrentUserCompany().then(function (data) {
                                    // notificationActivityResident(account.id)
                            $localStorage.companyId = CommonMethods.encryptIdUrl(data.companyId);
                            if (data.houses.length > 0) {
                                $localStorage.houseId = CommonMethods.encryptIdUrl(data.houses[0].id);
                                $rootScope.houseSelected = data.houses[0];
                            } else {
                                $localStorage.houseId = data.houseId;
                                $rootScope.houseSelected = data.houseClean[0];
                            }
                            $rootScope.currentUserImage = data.image_url;
                            $rootScope.companyUser = data;
                            $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                            $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_USER");
                            if (data.identificationnumber == undefined || data.identificationnumber == null) {
                                $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");

                            } else {
                                $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                            }
                            $localStorage.userType = CommonMethods.encryptIdUrl(data.type);
                            var companiesConfigArray = "";
                            CompanyConfiguration.get({id: data.companyId}, function (companyConfig) {
                                vm.backgroundSelectCompany = true;
                                $rootScope.currency = companyConfig.currency;
                                AdministrationConfiguration.get({companyId: data.companyId}, function (result) {
                                    var administrationConfiguration = result;
                                    companiesConfigArray += defineCompanyConfig(companyConfig, administrationConfiguration);
                                    $localStorage.companiesConfig = CommonMethods.encryptIdUrl(companiesConfigArray);
                                });
                                $rootScope.companyConfigsLoaded = true;
                                Company.get({id: parseInt(globalCompany.getId())}, function (condo) {
                                    vm.contextLiving = condo.name;
                                    vm.company = condo;
                                    $rootScope.company = condo;
                                    $rootScope.contextLiving = vm.contextLiving;
                                    EmailConfiguration.getEmailConfigurationByCompany(
                                        {
                                            companyId: globalCompany.getId()
                                        },
                                                function (emailConfig) {
                                                    if (emailConfig.adminCompanyName == $rootScope.adminCompany.companyName) {
                                                        if (condo.active == 0 || data.enabled == 0) {
                                                            logout();
                                                        } else {
                                                            hideLogin();
                                                            if (CommonMethods.detectMob()) {
                                                                $state.go("home-mobile-menu", {}, {reload: true});
                                                            } else {
                                                                $state.go("announcement-user", {}, {reload: true});
                                                            }
                                                        }
                                                    } else {
                                                        logout();
                                                        Modal.toast("Credenciales inválidos.");
                                                    }
                                                }
                                            );
                                });
                            });
                        });
                        break;
                    case "ROLE_OWNER":
                        MultiCompany.getCurrentUserCompany().then(function (data) {
                                        // notificationActivityResident(account.id)
                            $rootScope.houseSelected = data.houses[0];
                            $localStorage.companyId = CommonMethods.encryptIdUrl(data.companyId);
                            $localStorage.houseId = CommonMethods.encryptIdUrl(data.houses[0].id);
                            $rootScope.currentUserImage = data.image_url;
                            $rootScope.companyUser = data;
                            $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                            $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_USER");
                            $localStorage.userType = CommonMethods.encryptIdUrl(data.type);
                            if (data.identificationnumber == undefined || data.identificationnumber == null) {
                                $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
                            } else {
                                $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                            }
                            $localStorage.houseSelected = data.houses[0];
                            var companiesConfigArray = "";
                            CompanyConfiguration.get({id: data.companyId}, function (companyConfig) {
                                vm.backgroundSelectCompany = true;
                                $rootScope.currency = companyConfig.currency;
                                AdministrationConfiguration.get({companyId: data.companyId}, function (result) {
                                    var administrationConfiguration = result;
                                    companiesConfigArray += defineCompanyConfig(companyConfig, administrationConfiguration);
                                    $localStorage.companiesConfig = CommonMethods.encryptIdUrl(companiesConfigArray);
                                });
                                $rootScope.companyConfigsLoaded = true;
                                Company.get({id: parseInt(globalCompany.getId())}, function (condo) {
                                    vm.contextLiving = condo.name;
                                    vm.company = condo;
                                    $rootScope.company = condo;
                                    $rootScope.contextLiving = vm.contextLiving;
                                    EmailConfiguration.getEmailConfigurationByCompany(
                                        {
                                            companyId: globalCompany.getId()
                                        },
                                                    function (emailConfig) {
                                                        if (emailConfig.adminCompanyName == $rootScope.adminCompany.companyName) {
                                                            if (condo.active == 0 || data.enabled == 0) {
                                                                logout();
                                                            } else {
                                                                hideLogin();
                                                                if (CommonMethods.detectMob()) {
                                                                    $state.go("home-mobile-menu", {}, {reload: true});
                                                                } else {
                                                                    $state.go("announcement-user", {}, {reload: true});
                                                                }
                                                            }
                                                        } else {
                                                            logout();
                                                            Modal.toast("Credenciales inválidos.");
                                                        }
                                                    }
                                                );

                                });
                            });
                        }
                                );
                        break;
                    case
                            "ROLE_RH"
                            :
                        $rootScope.active = "company-rh";
                        $state.go('company-rh');
                        $rootScope.companyConfigsLoaded = true;
                        break;
                    case
                            "ROLE_JD"
                            :
                        MultiCompany.getCurrentUserCompany().then(function (data) {
                            $rootScope.companyUser = data;
                            $rootScope.showSelectCompany = false;
                            CommonMethods.setCompanyId(data.companyId);
                            $rootScope.companyId = data.companyId;
                            var companiesConfigArray = "";
                            hideLogin();
                            CompanyConfiguration.get({id: data.companyId}, function (companyConfig) {
                                vm.backgroundSelectCompany = true;
                                $rootScope.currency = companyConfig.currency;
                                AdministrationConfiguration.get({companyId: data.companyId}, function (result) {
                                    var administrationConfiguration = result;
                                    companiesConfigArray += defineCompanyConfig(companyConfig, administrationConfiguration);
                                    $localStorage.companiesConfig = CommonMethods.encryptIdUrl(companiesConfigArray);
                                });
                                $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_JD");
                                Company.get({id: globalCompany.getId()}, function (condo) {
                                    vm.contextLiving = condo.name;
                                    $rootScope.companyName = condo.name;
                                    $rootScope.contextLiving = vm.contextLiving;
                                    $rootScope.currentUserImage = null;
                                    $rootScope.companyUser.name = "Junta";
                                    $rootScope.companyUser.lastname = "Directiva";
                                    vm.company = condo;
                                    $rootScope.company = condo;
                                    vm.backgroundSelectCompany = true;
                                    $rootScope.companyConfigsLoaded = true;
                                    $state.go('announcement-user');
                                }, 300);
                                if (data.enabled == 0) {
                                    logout();
                                }
                            });
                        });
                        break;
                    }
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                            $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('home');
                    }
                }
                );
                $rootScope.$broadcast('authenticationSuccess');
                $('body').addClass("gray");

// previousState was set in the authExpiredInterceptor before being redirected to login modal.
// since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (a) {
                vm.authenticationError = true;
                Modal.toast("Credenciales inválidos.");
            });
        }

//        function register () {
////            $uibModalInstance.dismiss('cancel');
//            $state.go('register');
//        }
        vm.showAdvanced = function () {
            $mdDialog.show({
                templateUrl: 'app/components/login/privacy-terms.html',
                controller: DialogController,
                parent: angular.element(document.body),
                clickOutsideToClose: false,
            }).then(function (answer) {
                $localStorage.acceptedConditions = "ACCEPTED";
            }, function () {
                logout();
            });
        };

        function DialogController($scope, $mdDialog) {
            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.answer = function (answer) {
                $mdDialog.hide(answer);
            };
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})
();
